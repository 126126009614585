import React, { Suspense, useState } from "react";
import { Navigate, Route, Routes, BrowserRouter } from "react-router-dom"; // Import BrowserRouter
import "@coreui/coreui/dist/css/coreui.min.css";
import "bootstrap/dist/css/bootstrap.min.css";

import "./App.css";
import Login from "./pages/Login";

const Dashboard = React.lazy(() => import("./pages/Dashboard"));

function App() {
  return (
    <BrowserRouter> {/* Wrap your app with BrowserRouter */}
      <Suspense>
        <Routes>
          <Route exact path="/" element={<Dashboard />} />
          <Route exact path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to={"/login"} />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
