import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilLockLocked, cilUser } from "@coreui/icons";
import axios from "axios";
import {API_URL} from "../../config";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [disable, setDisable] = useState(false);
  const [error, setError] = useState("");
  const [errorVisible, setErrorVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Dashboard Login";
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisable(true);

    // Create a payload with email and password
    const payload = {
      email: email,
      password: password,
    };

    // Make a POST request to the login API endpoint
    axios
      .post(`${API_URL}/api/login/`, payload)
      .then((response) => {
        // Check if the login was successful (you may need to adjust this based on your API response format)
        if (response.status === 200) {
          // Redirect to the desired page upon successful login
          navigate("/"); // Replace with the appropriate route
          localStorage.setItem("token", response.data.token)
          localStorage.setItem("username", response.data.username)

        } else {
          setError("Login failed. Please check your credentials.");
          setErrorVisible(true);
          setDisable(false);
        }
      })
      .catch((error) => {
        // Handle errors from the API (e.g., bad request)
        if (error.response) {
          setError(error.response.data.error || "An error occurred.");
        } else {
          setError("An error occurred.");
        }
        setErrorVisible(true);
        setDisable(false);
      });
  };

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm onSubmit={handleSubmit}>
                    <h1>Login</h1>
                    <p className="text-medium-emphasis">Sign In to your account</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        placeholder="Email"
                        autoComplete="email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        disabled={disable}
                        required
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        placeholder="Password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        disabled={disable}
                        required
                      />
                    </CInputGroup>
                    <CAlert
                      className="mt-3"
                      color="danger"
                      dismissible
                      visible={errorVisible}
                      onClose={() => setErrorVisible(false)}
                    >
                      {error}
                    </CAlert>
                    <CRow>
                      <CCol xs={6}>
                        <CButton color="primary" className="px-4" type="submit" disabled={disable}>
                          Login
                        </CButton>
                      </CCol>
                      {/* Rest of your component code */}
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Login;
